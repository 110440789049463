/**
 * Direttiva che fissa nella parte superiore della finestra un elemento non appena la raggiunge
 */

export interface IFixToTopDirectiveAttributes extends ng.IAttributes {
    setClass: string;
}

angular.module('app').directive('fixToTop', ($window, $timeout, $state) => {
    // Collegamento alla finestra corrente
    let $win = angular.element($window);

    return {
        restrict: "AEC",
        link: (scope: ng.IScope, element: JQuery, attrs: IFixToTopDirectiveAttributes) => {
            // Devo aspettare il caricamento della pagina per prevenire l'autoscroll del browser se vengo da un'altra pagina in cui ho già eseguito lo scroll
            angular.element(document).ready(() => {
                let setClass = attrs.setClass, // classe da aggiungere
                    parent = element.parent(); // genitore dell'elemento
                let offsetTop: any = null; // distanza dell'elemento dal top

                // sto in ascolto dello scroll, così aggiorno sempre la posizione dell'elemento e, qualora raggiungesse il top, ci aggiungo la classe
                $win.on("scroll",  () => {
                    offsetTop = parent.offset().top;
                    if ($win.scrollTop() >= offsetTop) {
                        // Aggiungo all'elemento la classe desiderata e passata alla direttiva
                        if(setClass){
                            element.addClass(setClass);
                        }
                    } else {
                        // Tolgo all'elemento la classe desiderata e passata alla direttiva                    
                        if(setClass){
                            element.removeClass(setClass);
                        }
                    }
                });
            });
        }
    };
});